import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import vuetify from '@/plugins/vuetify.js'
import VueGtag from 'vue-gtag'
import { createHead } from '@unhead/vue'


axios.defaults.baseURL = 'https://api.bouquetgemini.com'

const head = createHead()

createApp(App)
    .use(store)
    .use(router, axios)
    .use(vuetify)
    .use(VueGtag, {
        config: { id: 'G-VD917G65N3' }
    }, router)
    .use(head)
    .mount('#app')
