<template>
    <h1>My Account</h1>

    <v-btn @click="logout">Log Out</v-btn>

</template>

<script>
import axios from 'axios'

export default {
    name: 'MyAccount',
    methods: {
        logout () {
            axios.defaults.headers.common["Authorization"] = ""

            localStorage.removeItem('token')
            localStorage.removeItem('username')
            localStorage.removeItem('userid')

            this.$store.commit('removeToken')
            this.$router.push('/')
        }
    }
}
</script>