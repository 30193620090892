<template>
    <div>
        <!---
        <v-img
            class="mx-auto my-6"
            max-width="228"
            src="https://cdn.vuetifyjs.com/docs/images/logos/vuetify-logo-v3-slim-text-light.svg"
        ></v-img>
        --->
  
      <v-card
        class="mx-auto pa-12 pb-8"
        elevation="8"
        max-width="448"
        rounded="lg"
      >
        <h1 class="text-center">Daftar</h1>
        <v-text-field
          density="compact"
          placeholder="Username"
          prepend-inner-icon="mdi-account"
          variant="outlined"
          v-model="username"
        ></v-text-field>

        <v-text-field
          density="compact"
          placeholder="Email"
          prepend-inner-icon="mdi-email-outline"
          variant="outlined"
          v-model="email"
        ></v-text-field>

        <v-text-field
          density="compact"
          placeholder="Nomor Telepon"
          prepend-inner-icon="mdi-phone"
          variant="outlined"
          v-model="phonenumber"
        ></v-text-field>
  
        <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
          Password
        </div>
        <v-text-field
          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'"
          density="compact"
          placeholder="Enter your password"
          prepend-inner-icon="mdi-lock-outline"
          variant="outlined"
          @click:append-inner="visible = !visible"
          v-model="password"
        ></v-text-field>

        <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
          Confirm Password
        </div>
        <v-text-field
          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'"
          density="compact"
          placeholder="Enter your confirm password"
          prepend-inner-icon="mdi-lock-outline"
          variant="outlined"
          @click:append-inner="visible = !visible"
          v-model="password2"
        ></v-text-field>
  
  
        <!---
        <v-card
          class="mb-12"
          color="surface-variant"
          variant="tonal"
        >
          <v-card-text class="text-medium-emphasis text-caption">
            Warning: After 3 consecutive failed login attempts, you account will be temporarily locked for three hours. If you must login now, you can also click "Forgot login password?" below to reset the login password.
          </v-card-text>
        </v-card>
  
        ---->
        <v-alert
            class="mb-5"
            v-for="error in errors"    
            v-bind:key="error"
            :text="error"
            type="error"
        ></v-alert>

        <v-btn
          class="mb-10"
          color="pink-accent-1"
          size="large"
          variant="tonal"
          block
          @click="submitForm"
        >
          Sign Up 
        </v-btn>
  
        <v-card-text class="text-center">
          <router-link class="text-blue  text-decoration-none" to="/log-in">
            Log in<v-icon icon="mdi-chevron-right"></v-icon>
        
          </router-link>
        </v-card-text>

      </v-card>
    </div>
  </template>
  <script>
    import axios from 'axios'
    import { toast } from "vue3-toastify";

    export default {
      data: () => ({
        visible: false,
        username: '',
        phonenumber: '',
        password: '',
        password2: '',
        errors: []
      }),
      mounted () {
        document.title = 'Sign Up | Bouquet Gemini'
      },
      methods: {
        submitForm () {
            this.errors = []
            if (this.username === '') {
                this.errors.push('Username is missing')
            }

            if (this.password === '') {
                this.errors.push('Password is missing')
            }

            if (this.phonenumber === '') {
                this.errors.push('Phone number is missing')
            }

            if (this.phonenumber.length >= 15) {
                this.errors.push('Phone number length >= 15')
            }

            if (this.password !== this.password2) {
                this.errors.push('The passwords doesnt\'t match')
            }

            if (!this.errors.length) {
                const formData = {
                    username: this.username,
                    password: this.password,
		    password2: this.password2,
                }
                axios
                    .post("/auth/registration/", formData)
                    .then(response => {
                        toast("Account created, Please log in", {
                        "theme": "colored",
                        "type": "success",
                        "autoClose": 2000,
                        })    
                        this.$router.push('/log-in')
                    })
                    .catch(error => {
                        console.log('error: ')
                        console.log(error)
                        if (error.response) {
                            for (const property in error.response.data) {
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }
                        } else if (error.message) {
                            this.errors.push('Something went wrong, Please try again')
                        }
                    })
            }

        }
      }
    }
  </script>
