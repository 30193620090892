<template>
  <div class="v-container">
    <v-row justify="space-around">
      <v-col class="mt-2">
        <v-row>
            <v-img
              :aspect-ratio="1"
              class="bg-white"
              :src="mainImageUrl"
              :alt="product.name"
              height="692"
              width="692"
              cover
            ></v-img>
        </v-row>
      </v-col>
      <v-col class="ml-3 mt-2">
        <v-sheet class="pa-2">
            <h1>
              {{ product.name }}
            </h1>
        </v-sheet>
        <v-sheet class="pa-2">
            <h2>
              Rp. {{  formatMoney(product.price)  }}
            </h2>
        </v-sheet>
        <v-sheet>
            <p>
              {{ product.description }}
            </p>
        </v-sheet>
        <v-row class="mt-3">
            <v-col>
                <v-text-field
                    variant="outlined"
                    v-model="quantity"
                ></v-text-field>

            </v-col>
            <v-col>
                <v-btn prepend-icon="mdi-cart" color="black" @click="addToCart">
                    Add To Cart
                </v-btn>
            </v-col>
        </v-row>
        <v-btn @click="checkOutViaWA" to="/cart" color="green" rounded block>Beli Sekarang    
            <i class="fa-brands fa-whatsapp"></i>
        </v-btn>
      </v-col>
    </v-row>
    <v-col cols="6" class="mt-4">
        <v-row justify="start" align-self="start">
            <v-col :cols="$vuetify.display.mobile ? 8 : 6">
                <v-img
                    :aspect-ratio="1"
                    class="bg-white"
                    :src="product.get_image"
                    :alt="product.name"
                    cover
                    @click.prevent="selectMainImage(product.get_image)"
                ></v-img>
            </v-col>
            <v-col :cols="$vuetify.display.mobile ? 8 : 6" v-for="i in product.images">
                <v-img
                    :aspect-ratio="1"
                    class="bg-white"
                    :src="i.get_image"
                    :alt="product.name"
                    cover
                    @click.prevent="selectMainImage(i.get_image)"
                ></v-img>
            </v-col>
        </v-row>
    </v-col>
</div>
</template>

<script>
import axios from 'axios'
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useSeoMeta } from '@unhead/vue';

export default {
    name: 'Product',
    data() {
        return {
            product: {},
            quantity: 1,
            mainImageUrl: ''
        }
    },
    async mounted () {
        await this.getProduct()
        await useSeoMeta({
            description: this.product.description,
            ogDescription: this.product.description,
            ogUrl: location.host,
            ogType: 'website',
        })
    },
    methods: {
        selectMainImage (url) {
            this.mainImageUrl = url
        },
        async checkOutViaWA () {
            await this.addToCart()
            this.$router.push('/cart')
        },
        async getProduct () {
            this.$store.commit('setIsLoading', true)

            const category_slug = this.$route.params.category_slug
            const product_slug = this.$route.params.product_slug

            await axios
                .get(`/api/v1/products/${category_slug}/${product_slug}`)
                .then(response => {
                    this.product = response.data
                    this.mainImageUrl = this.product.get_image
                    document.title = this.product.name + ' | Bouquet Gemini | Buket Gemini'
                })
                .catch(error => {
                    console.log(error)
                    toast("Something went wrong, Please try again", {
                    "theme": "auto",
                    "type": "error",
                    "autoClose": 2000,
                    })    
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        },
        addToCart () {
            if (isNaN(this.quantity) || this.quantity < 1) {
                this.quantity = 1
            }
            const item = {
                product: this.product,
                quantity: this.quantity
            }

            this.$store.commit('addToCart', item)
            toast("The Product was added to the cart", {
              "theme": "colored",
              "type": "success",
              "autoClose": 2000,
            })    
            this.$gtag.event('add-to-cart', {
                'event_category': 'cart',
                'event_label': 'Add Product to cart',
                'value': item.product.name
            })
        }
    }
}
</script>

<script setup>
    function formatMoney(value) {
        return new Intl.NumberFormat('en-GB').format(value).replace(',', '.')
    }
</script>
