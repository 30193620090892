<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="about-section">
          <h1 class="headline mb-4 text-center">Tentang Kami</h1>
          <h2 class="headline mb-4 text-center">Bouquet Gemini</h2>
          <v-divider class="mb-4"></v-divider>
            <p class="text-center text-body-1">
                    Bouquet Gemini berdiri tahun 2021 dimana saat pandemi Covid-19 melanda banyak Sebagian orang yang diam dirumah tidak diperbolehkan kemana-mana binggung untuk memberikan bingkisan ke orang yang tersayang disitu Buket Gemini hadir terispirasi dengan trobosan memberikan kebahagiaan untuk orang yang tercinta yang tidak bisa memberi hadiah secara langsung dari wujud kasih sayang. 
Bouquet Gemini Bergerak di bidang Handcraft kreatif kerajinan tangan buket Bungga, Boneka, dan lain lain nya.
            </p>
            <br>
            <p class="text-center text-body-1">
              Dalam perjalanan Bouquet Gemini Berfokus untuk Melayanin pelanggan di JABODETABEK  dengan layanan pengiriman Instan Bouquet Gemini bersemangat menciptakan perangkaian bunga indah dan berkesan untuk berbagai acara, mencakup wisuda, anniversary, acara duka, dan acara penting lainnya. Lebih dari sekedar bunga, Bouquet Gemini menawarkan beragam aksesori seperti cokelat, balon, boneka, dan lainnya untuk melengkapi kebutuhan pelanggan. Dengan inovasi dan kualitas layanan yang unggul, Bouquet Gemini sukses meraih banyak pelanggan.
              Pelanggan tidak hanya merasa puas dengan produk yang ditawarkan, tetapi juga menikmati pengalaman belanja yang menyenangkan dan mudah.
            </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { useSeoMeta } from '@unhead/vue';
  export default {
    name: 'About',
    mounted() {
        useSeoMeta({
            title: 'About | Tentang Kami | Bouquet Gemini | Buket Gemini',
            description: 'Bouquet Gemini berdiri tahun 2021 dimana saat pandemi Covid-19 melanda banyak Sebagian orang yang diam dirumah tidak diperbolehkan kemana-mana binggun',
            ogUrl: location.host,
            ogType: 'website',
            ogTitle: 'About | Tentang Kami | Bouquet Gemini',
            ogDescription: 'Bouquet Gemini berdiri tahun 2021 dimana saat pandemi Covid-19 melanda banyak Sebagian orang yang diam dirumah tidak diperbolehkan kemana-mana binggun',
        })
    }
  }
</script>
