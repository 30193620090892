<template>
    <div>
        <v-container>
            <div class="text-h4 text-center mt-3"> Search: {{ query }} </div>
            <v-row class="mt-10">
                <ProductBox :product="product" v-for="product in products"></ProductBox>
            </v-row>
        </v-container>
    </div>

</template>

<script>
import axios from 'axios'
import ProductBox from '@/components/ProductBox.vue'

export default {
    components: {
        ProductBox
    },
    data () {
        return {
            products: [],
            query: ''
        }
    },
    methods: {
        async performSearch () {
            this.$store.commit('setIsLoading', true)

            await axios
                .post('/api/v1/products/search/', {'query': this.query})
                .then(response => {
                    this.products = response.data
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        }
    },
    mounted () {
        document.title = 'Search | Bouquet Gemini | Buket Gemini'

        let uri = window.location.search.substring(1)
        let params = new URLSearchParams(uri)

        if (params.get('query')) {
            this.query = params.get('query')
            this.performSearch()
        }
    },
}

</script>
