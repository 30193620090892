<template>
    <v-container>
        <h1>
            Keranjang Belanja
        </h1>
        <v-table v-if="cartTotalLength">
        <thead>
            <tr>
            <th class="text-left">
            </th>
            <th class="text-left">
                Nama Produk
            </th>
            <th class="text-left">
                Harga
            </th>
            <th class="text-left">
                Jumlah
            </th>
            <th class="text-left">
                Total
            </th>
            <th class="text-left">
                Aksi
            </th>
            </tr>
        </thead>
        <tbody>
            <CartItem 
                v-for="item in cart.items"
                v-bind:key="item.product.id"
                v-bind:initialItem="item"
                v-on:removeFromCart="removeFromCart">
            </CartItem>
            <!--
            <tr
            v-for="item in desserts"
            :key="item.name"
            >
            <td>{{ item.name }}</td>
            <td>{{ item.calories }}</td>
            </tr>
            -->
        </tbody>
        </v-table>
        <v-sheet v-else color="blue-grey-lighten-5" max-width="100%" height="500" class="text-center ma-10 pa-4 mx-auto">
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <v-icon icon="mdi-cart-remove" size="x-large"></v-icon>
            <h2 class="text-h5 mb-6">
                You dont't have any products in you cart...
            </h2>
            <p>
                <v-btn to="/" color="black"> Belanja Sekarang ></v-btn>
            </p>
        </v-sheet>

        <br>
        <br>
        <h2> TOTAL </h2>
        <br>

        <tr>
            <td>
                <strong>Rp. {{ formatMoney(cartTotalPrice) }}</strong>, {{  cartTotalLength }} items
            </td>
        </tr>

        <br>
        <br>
        <br>
        <br>
        <v-btn @click="checkOutViaWA" to="/cart" color="green" rounded>Beli Sekarang    
            <v-icon icon="mdi-whatsapp"></v-icon>
        </v-btn>
    </v-container>
  </template>

<script>
import axios from 'axios'
import CartItem from '@/components/CartItem.vue'
import { toast } from "vue3-toastify";
import { useSeoMeta } from '@unhead/vue';

export default {
    name: 'Cart',
    components: {
        CartItem
    },
    data () {
        return {
            hrefCheckout: '',
            cart: {
                items: []
            }
        }
    },
    mounted() {
        this.cart = this.$store.state.cart
        
        useSeoMeta({
            title: 'Keranjang | Bouquet Gemini',
            description: 'Keranjang buket gemini',
            ogUrl: location.host,
            ogType: 'website',
            ogTitle: 'Keranjang | Bouquet Gemini | Buket Gemini',
            ogDescription: 'Keranjang buket gemini'
        })
    },
    methods: {
        formatMoney(value) {
            return new Intl.NumberFormat('en-GB').format(value).replace(',', '.').replace(',', '.')
        },
        removeFromCart (item) {
            this.cart.items = this.cart.items.filter(i => i.product.id !== item.product.id)

            this.$gtag.event('remove-from-cart', {
                'event_category': 'cart',
                'event_label': 'Remove product from cart',
                'value': item.product.name
            })
        },
        checkOutViaWA () {
            if (this.cart.items.length === 0) {
                toast("Keranjang Kosong, Ayo belanja", {
                "theme": "colored",
                "type": "warning",
                "autoClose": 2000,
                })    
                return
            }

            this.$gtag.event('checkout-product-by-wa', {
                'event_category': 'checkout',
                'event_label': 'Checkout all product by WA',
                'value': 1
            })

            let templateText = "Hallo Kak, saya mau order%0a"
            let no = 1
            this.cart.items.forEach(element => {
                let row1 = `${no}. ${element.product.name}%0a`
            
                //PRODUCT NAME
                templateText += row1.replace('&', 'and')
                templateText += `Jumlah: ${element.quantity}%0a`
                templateText += `Harga: Rp. ${this.formatMoney(element.product.price)}%0a`
                no++
            })
            templateText += `Total: Rp. ${this.formatMoney(this.cartTotalPrice)}%0a`
            console.log(templateText)
            window.location.href=`https://api.whatsapp.com/send?phone=6281331706861&text=${templateText}`
        }
    },
    computed: {
        cartTotalLength () {
            return this.cart.items.reduce((acc, curVal) => {
                return acc += curVal.quantity
            }, 0)
        },
        cartTotalPrice () {
            return this.cart.items.reduce((acc, curVal) => {
                return acc += curVal.product.price * curVal.quantity
            }, 0)
        }
    },
}

</script>
