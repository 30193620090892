<template>
    <v-col :key="product.id" :cols="$vuetify.display.mobile ? 6 : 12" md="3">
      <v-hover>
        <template v-slot:default="{ isHovering, props }">
              <v-card
                class="mx-auto"
                color="grey-lighten-4"
                max-width="600"
                v-bind="props"
                v-bind:href="product.get_absolute_url"
              >
                <v-img
                  :src="product.get_image"
                  :alt="product.name"
                  cover
                  height="300"
                >
                <v-expand-transition>
                <div
                  v-if="isHovering"
                  class="d-flex transition-fast-in-fast-out bg-pink-lighten-5 v-card--reveal text-h2"
                  style="height: 100%;"
                >
                  <!--- Rp. {{ formatMoney(product.price) }} --->
                </div>
              </v-expand-transition>
              </v-img>
            </v-card>
        </template>
      </v-hover>
      <v-card-text class="pt-6">
        <h3>
            {{ product.name }}
        </h3>

        <v-row align-self="" justify="center" v-if="product.price_discount">
          <v-col cols="6">
            <div class="font-weight-light text-h6 text-truncate" v-if="product.price_discount">
              Rp.{{ formatMoney(product.price_discount) }}
            </div>
          </v-col>
          <v-col cols="6">
            <div  class="font-weight-light text-h6 text-truncate" :class="[product.discount ? 'text-decoration-line-through text-subtitle-1 text-red' : '']">
              Rp. 111{{ formatMoney(product.price) }}
            </div>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <div  class="font-weight-light text-h6 text-truncate" :class="[product.discount ? 'text-decoration-line-through text-subtitle-1 text-red' : '']">
              Rp. {{ formatMoney(product.price) }}
            </div>
          </v-col>
        </v-row>

      </v-card-text>
      <v-card-actions>
        <!-----
        <v-btn color="green" text>
          Rp.{{ formatMoney(product.price) }}
        </v-btn>
        --->
      </v-card-actions>
      <!--- 
      <v-card class="mx-auto" max-width="344" outlined>
        <v-card-title>{{ product.name }}</v-card-title>
        <v-card-subtitle>
          {{ product.description }}
        </v-card-subtitle>
        <v-card-actions>
          <v-btn color="primary" text>
            Rp.{{ formatMoney(product.price) }}
          </v-btn>
          <v-btn variant='elevated' color='secondary' v-bind:to="product.get_absolute_url">View Detail</v-btn>
        </v-card-actions>
      </v-card>
      --->
    </v-col>

</template>

<script>
export default {
    name: 'ProductBox',
    props: {
        product: Object
    }
}
</script>

<script setup>
    function formatMoney(value) {
        return new Intl.NumberFormat('en-GB').format(value).replace(',', '.')
    }
</script>
