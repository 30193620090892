<template>
    <div>
        <v-container>
            <div class="text-center mt-3">
                <h1> {{ category.name }} </h1>
            </div>
            <v-row class="mt-10">
                <ProductBox :product="product" v-for="product in category.products"></ProductBox>
            </v-row>
            <div class="text-center mt-3">
                <p>
                    {{ category.description }}
                </p>
            </div>
        </v-container>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useSeoMeta } from '@unhead/vue';
import ProductBox from '@/components/ProductBox.vue'

export default {
    name: 'Category',
    components: {
        ProductBox
    },
    data () {
        return {
            category: {
                products: []
            }
        }
    },
    async mounted () {
        await this.getCategory()
        await useSeoMeta({
            description: this.category.description,
            ogDescription: this.category.description,
            ogUrl: location.host,
            ogType: 'website',
        })
    },
    watch: {
        $route(to, from) {
            if (to.name === 'Category') {
                this.getCategory()
            }
        }
    },
    methods: {
        async getCategory() {
            const categorySlug = this.$route.params.category_slug
            this.$store.commit('setIsLoading', true)

            await axios
                .get(`/api/v1/products/${categorySlug}`)
                .then(response => {
                    this.category = response.data
                    document.title = this.category.name + "| Bouquet Gemini"
                })
                .catch(error => {
                    toast("Something went wrong, Please try again", {
                    "theme": "auto",
                    "type": "error",
                    "autoClose": 2000,
                    })    
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>
