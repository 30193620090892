<template>
    <div>
      <v-card
        class="mx-auto pa-12 pb-8"
        elevation="8"
        max-width="448"
        rounded="lg"
      >
        <h1 class="text-center">Log In</h1>

        <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
          Account
        </div>

        <v-text-field
          density="compact"
          placeholder="Username"
          prepend-inner-icon="mdi-account"
          variant="outlined"
          v-model="username"
        ></v-text-field>

        <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
          Password
        </div>
        <v-text-field
          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'"
          density="compact"
          placeholder="Enter your password"
          prepend-inner-icon="mdi-lock-outline"
          variant="outlined"
          @click:append-inner="visible = !visible"
          v-model="password"
        ></v-text-field>

        <!---
        <v-card
          class="mb-12"
          color="surface-variant"
          variant="tonal"
        >
          <v-card-text class="text-medium-emphasis text-caption">
            Warning: After 3 consecutive failed login attempts, you account will be temporarily locked for three hours. If you must login now, you can also click "Forgot login password?" below to reset the login password.
          </v-card-text>
        </v-card>
  
        ---->
        <v-btn
          class="mb-10"
          color="pink-accent-1"
          size="large"
          variant="tonal"
          block
          @click="submitForm"
        >
          Log In
        </v-btn>
  
        <v-card-text class="text-center">
          <router-link class="text-blue  text-decoration-none" to="/sign-up">
            Sign Up<v-icon icon="mdi-chevron-right"></v-icon>
        
          </router-link>
        </v-card-text>
      </v-card>
    </div>
  </template>
  <script>
  import axios from 'axios'
    export default {
      data: () => ({
        username: '',
        password: '',
        errors: []
      }),
      mounted () {
        document.title = 'Log In | Bouquet Gemini'
      },
      methods: {
        async submitForm () {
          axios.defaults.headers.common["Authorization"] = ""

          localStorage.removeItem("token")

          const formData = {
            username: this.username,
            password: this.password
          }

          await axios
            .post("/auth/login/", formData)
            .then(res => {
              const token = res.data.key

              this.$store.commit('setToken', token)

              axios.defaults.headers.common["Authorization"] = "Token " + token


              localStorage.setItem('token', token)

              const toPath = this.$route.query.to || '/cart'

              this.$router.push(toPath)
            })
            .catch(error => {
                if (error.response) {
                    for (const property in error.response.data) {
                        this.errors.push(`${property}: ${error.response.data[property]}`)
                    }
                } else if (error.message) {
                    this.error.push('Something went wrong, Please try again')
                }
            })


        }
      }
    }
  </script>
