<template>
  <v-layout>
    <v-system-bar color="pink-darken-3">
        <v-row v-if="$vuetify.display.mobile">
            <v-col>
                <div class="text-center">
                    1000+ reviews | FREE ONGKIR
                </div>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="2" align-self="start">
                <div class="text-left">
                    <v-icon icon="mdi-whatsapp mb-1" />
                    +62 813-3170-6861
                </div>
            </v-col>
            <v-col cols="3">
                <div class="text-left">
                    <v-icon icon="mdi-email mb-1" />
                    buketgemini@gmail.com
                </div>
            </v-col>
            <v-col cols="2">
                <div class="text-center">
                    1000+ reviews | FREE ONGKIR
                </div>
            </v-col>
            <v-col cols="5">
                <a class="text-white text-decoration-none ma-2" href="https://instagram.com/buketgemini">
                    <v-icon icon="mdi-instagram"></v-icon>
                </a>
                <a class="text-white text-decoration-none fab fa-brands fa-tiktok" href="https://www.tiktok.com/@buketgemini">
                </a>
            </v-col>
        </v-row>
    </v-system-bar>
    <v-app-bar elevation="0" prominent :height="96">
      <v-app-bar-nav-icon v-if="$vuetify.display.mobile" variant="text" @click.stop="drawer = !drawer; goTo(0, goToOptions)">
        <transition name="bounce">
          <v-icon>{{ drawer ? 'mdi-close' : 'mdi-menu' }}</v-icon>
        </transition>
      </v-app-bar-nav-icon>

	  <v-container fluid v-if="!$vuetify.display.mobile">
		<v-row align="center" class="justify-space-between" no-gutters>
		  <v-col cols="auto">
			<v-row class="justify-left">
			  <v-list-item link to="/fresh_flowers_bouquet" title="Fresh Flowers Bouquet" @click="goTo(0, goToOptions)"></v-list-item>
			  <v-list-item link to="/flowers_bouquet_boneka" title="Flowers Bouquet & Boneka" @click="goTo(0, goToOptions)"></v-list-item>
			</v-row>
		  </v-col>
		  <v-col cols="3" class="d-flex justify-center" style="padding-left: 195px;">
			  <router-link to="/" class="text-black text-decoration-none">
				<v-img alt="buket gemini" :src="require(`./assets/logo_buketgemini.png`)" :height="90" :width="170" @click="goTo(0, goToOptions)" cover></v-img>
			  </router-link>
		  </v-col>
		  <v-col cols="auto">
			<v-row class="justify-end">
			<v-list-item link to="/flowers_bouquet_chocolate" title="Flowers Bouquet & Chocolate" @click="goTo(0, goToOptions)"></v-list-item>
			  <v-list-item link to="/bloom_box" title="Bloom Box" @click="goTo(0, goToOptions)"></v-list-item>
			 <v-menu
			  open-on-hover
			 >
			  <template v-slot:activator="{ props }">
				<v-btn
				  v-bind="props"
				  append-icon="mdi-chevron-down"
				>
					Lainnya
				</v-btn>
			  </template>
			  <v-list>
				<v-list-item link to="/boneka" title="Boneka" @click="goTo(0, goToOptions)"></v-list-item>
				<v-list-item link to="/chocolate" title="Chocolate" @click="goTo(0, goToOptions)"></v-list-item>
				<v-list-item link to="/bouquet_money" title="Buket Uang" @click="goTo(0, goToOptions)"></v-list-item>
				<v-list-item link to="/table_flower" title="Bunga Meja" @click="goTo(0, goToOptions)"></v-list-item>
				<v-list-item link to="/fresh_flowers_dekorasi_mobil" title="Fresh Flowers Dekorasi Mobil" @click="goTo(0, goToOptions)"></v-list-item>
				<v-list-item link to="/cake_kue_ultah" title="Cake & Kue Ultah" @click="goTo(0, goToOptions)"></v-list-item>
				<v-list-item link to="/bouquet_graduation" title="Bouquet Graduation" @click="goTo(0, goToOptions)"></v-list-item>
			  </v-list>
			</v-menu>
			</v-row>
		  </v-col>
		</v-row>
	  </v-container>

	  <v-container v-else>
		<v-row align="center" justify="center" no-gutters>
		  <v-col cols="auto">
			<v-toolbar-title>
			  <router-link to="/" class="text-black text-decoration-none">
			  <v-img alt="buket gemini" :src="require(`./assets/logo_buketgemini.png`)" :height="90" :width="170" @click="goTo(0, goToOptions)" cover></v-img>
			  </router-link>
			</v-toolbar-title>
		  </v-col>
		</v-row>
	  </v-container>

        <template v-if="!$vuetify.display.mobile" v-slot:append>
            <div class="text-center">
                <v-btn to="/cart" prepend-icon="mdi-cart" variant="plain" color="pink-darken-3">
                    ({{cartTotalLength}})
                    <template v-slot:prepend>
                        <v-icon color="pink-darken-3"></v-icon>
                    </template>
                </v-btn>
            </div>
            |
            <v-btn v-if="$store.state.isAuthenticated" icon="mdi-logout" @click="logout"></v-btn>
            <v-menu
                v-else
                transition="slide-x-transition"
            >
                <template v-slot:activator="{ props }">
                    <v-btn
                    v-bind="props"
                    icon="mdi-account"
                    >
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item>
                        <v-list-item-title>
                            <v-btn to="/log-in" color="pink-accent-2" size="large" rounded>Masuk</v-btn>
                        </v-list-item-title>
                        <v-list-item-title class="text-center mt-3">
                            <v-btn to="/sign-up" size="x-small" variant="text">Daftar</v-btn>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
      </v-app-bar>
    <v-main>
        <v-list v-if="drawer">
            <v-list-item link to="/bloom_box" title="Bloom Box" @click="drawer = !drawer"></v-list-item>
            <hr>
            <v-list-item link to="/fresh_flowers_bouquet" title="Fresh Flowers Bouquet" @click="drawer = !drawer"></v-list-item>
            <hr>
            <v-list-item link to="/flowers_bouquet_boneka" title="Flowers Bouquet & Boneka" @click="drawer = !drawer"></v-list-item>
            <hr>
            <v-list-item link to="/flowers_bouquet_chocolate" title="Flowers Bouquet & Chocolate" @click="drawer = !drawer"></v-list-item>
            <hr>
            <v-list-item link to="/boneka" title="Boneka" @click="drawer = !drawer"></v-list-item>
            <hr>
            <v-list-item link to="/chocolate" title="Chocolate" @click="drawer = !drawer"></v-list-item>
            <hr>
            <v-list-item link to="/bouquet_money" title="Buket Uang" @click="drawer = !drawer"></v-list-item>
            <hr>
	    <v-list-item link to="/table_flower" title="Bunga Meja" @click="drawer = !drawer"></v-list-item>
            <hr>
  	    <v-list-item link to="/fresh_flowers_dekorasi_mobil" title="Fresh Flowers Dekorasi Mobil" @click="drawer = !drawer"></v-list-item>
            <hr>
	    <v-list-item link to="/cake_kue_ultah" title="Cake & Kue Ultah" @click="drawer = !drawer"></v-list-item>
            <hr>
	    <v-list-item link to="/bouquet_graduation" title="Bouquet Graduation" @click="drawer = !drawer"></v-list-item>
            <hr>
			<v-list>
				<v-list-item>
					<v-list-item-title class="text-center mt-3">
						<v-btn to="/log-in" color="pink-accent-2" size="large" rounded>Masuk</v-btn>
					</v-list-item-title>
					<v-list-item-title class="text-center mt-3">
						<v-btn to="/sign-up" size="x-small" variant="text">Daftar</v-btn>
					</v-list-item-title>
				</v-list-item>
			</v-list>
        </v-list>
        <v-progress-linear
            :active="$store.state.isLoading"
            :indeterminate="$store.state.isLoading"
            color="pink-accent-4"
            height="3"
            bottom
        ></v-progress-linear>

        <v-responsive
            v-if="visibleFormSearch"
            class="mx-auto mt-5 mb-5 pa-5"
            max-width="500"
        >
            <form method="get" action="/search">
                <v-text-field
                    name="query"
                    label="Search..."
                    variant="solo"
                    single-line
                >
                <template v-slot:append="{ props }">
                    <v-btn v-bind="props" type="submit" icon="mdi-magnify">
                    </v-btn>
                </template>
            </v-text-field>
            </form>
        </v-responsive>
        <router-view></router-view>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <v-row>
          <v-col md="4" sm="12" class="d-flex justify-center pt-10">
            <a target="_blank" class="text-decoration-none" href="http://search.google.com/local/writereview?placeid=ChIJOYT_HRX3aS4RYZHrHDILvwU">
              <v-img alt="Review us on Google"
                :src="require(`./assets/review_us_on_google.png`)"
                height="300"
                width="250"
              ></v-img>
            </a>
          </v-col>
          <v-col md="8" sm="auto">
            <v-row class="scroll-container mt-5">
              <v-col 
                  v-for="(d, index) in dataReviews"
                  :key="index"
                  class="d-inline-block"
                  style="background-color: #58173459;"
                  cols="auto">
                    <ReviewCard :review="d" class="review-col"></ReviewCard>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <br>
        <br>
        <br>
        <br>
        <v-divider
            :thickness="2"
            class="border-opacity-25"
            color="pink-accent-3"
        ></v-divider>
				<div class="text-center d-flex flex-column">
					<span><a href="/" class="text-decoration-none text-black">Home</a> - Jalan Tomang Tinggi Raya No.17A Jakarta Barat</span>
				</div>
        <v-divider
            :thickness="2"
            class="border-opacity-25"
            color="pink-accent-3"
        ></v-divider>
        <v-footer>
            <v-col :cols="$vuetify.display.mobile ? 12 : 6">
                <v-row>
                    <v-col>
                        <h3 class="mb-4">Tentang Kami</h3>
                    </v-col>
                    <v-col>
                        <h3 class="mb-4">Temukan Saya</h3>
                    </v-col>
                </v-row>
                <!---row 1-->
                <v-row justify="start">
                    <v-col :cols="$vuetify.display.mobile ? 12 : 6">
                        <v-btn append-icon="mdi-arrow-top-right" variant="plain" href="/about">
                            Tentang Bouquet Gemini
                        </v-btn>
                    </v-col>
                    <v-col :cols="$vuetify.display.mobile ? 12 : 6">
                        <v-btn href="https://maps.app.goo.gl/fqGB9cLdNBHWPJ2r9" append-icon="mdi-arrow-top-right" variant="plain">
                            Location
                        </v-btn>
                    </v-col>
                </v-row>

                <!---row 2-->
                <v-row justify="start">
                    <v-col :cols="$vuetify.display.mobile ? 12 : 6">
                        <v-btn append-icon="mdi-arrow-top-right" variant="plain" href="/faq">
                            FAQ
                        </v-btn>
                    </v-col>
                    <v-col :cols="$vuetify.display.mobile ? 12 : 6">
                        <v-btn to="/myaccount" append-icon="mdi-arrow-top-right" variant="plain">
                            Akun Saya
                        </v-btn>
                    </v-col>
                </v-row>

                <!---row 3-->
                <v-row justify="start">
                    <v-col :cols="$vuetify.display.mobile ? 12 : 6">
                        <v-btn append-icon="mdi-arrow-top-right" variant="plain">
                            Review Pelanggan
                        </v-btn>
                    </v-col>
                    <v-col :cols="$vuetify.display.mobile ? 12 : 6">
                        <v-btn append-icon="mdi-arrow-top-right" variant="plain">
                            Cara Pemesanan
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-footer>
        <v-footer
            class="text-center d-flex flex-column"
        >
            <div>
                <v-btn
                    v-for="i in icons"
                    :key="i.icon"
                    :icon="i.icon"
                    :href="i.url"
                    class="mx-4 text-center"
                    variant="text"
                ></v-btn>
            </div>

            <v-divider></v-divider>

            <div>
            2024 — <strong>Bouquet Gemini</strong>
            </div>
				</v-footer>

        <v-divider
            :thickness="2"
            class="border-opacity-25"
            color="pink-accent-3"
        ></v-divider>

            <div class="mt-10 pl-5 pr-5">
							<v-row>
								<v-col :cols="$vuetify.display.mobile ? 12 : 6">
									<h3>Florist JABODETABEK</h3>
									<p class="text-subtitle-2">
											Bouquet Gemini berdiri tahun 2021 dimana saat pandemi Covid-19 melanda banyak Sebagian orang yang diam dirumah tidak diperbolehkan kemana-mana binggung untuk memberikan bingkisan ke orang yang tersayang disitu Buket Gemini hadir terispirasi dengan trobosan memberikan kebahagiaan untuk orang yang tercinta yang tidak bisa memberi hadiah secara langsung dari wujud kasih sayang.
									</p>
									<p class="text-subtitle-2">
				Bouquet Gemini Bergerak di bidang Handcraft kreatif  kerajinan tangan  Dimana kerajian tersebut Menyediakan Rangkaian Bunga Fresh dan Berbagai Macam Jenis bunga , Bunga Mawar, Matahari, Lily serta berbagai Gift Seperti Boneka,Hampers dan Coklat  dan sebagai Pemanis. Dalam perjalanan Bouquet Gemini Berfokus untuk Melayanin pelanggan di JABODETABEK  dengan layanan pengiriman Instan langsung Kirim ,Bouquet Gemini bersemangat menciptakan perangkaian bunga indah dan berkesan untuk berbagai acara, mencakup wisuda, anniversary, acara duka, dan acara penting lainnya. Lebih dari sekedar bunga, Bouquet Gemini menawarkan beragam aksesori seperti cokelat, balon, boneka, dan lainnya untuk melengkapi kebutuhan pelanggan. Dengan inovasi dan kualitas layanan yang unggul, Bouquet Gemini sukses meraih banyak pelanggan. Pelanggan tidak hanya merasa puas dengan produk yang ditawarkan, tetapi juga menikmati pengalaman belanja yang menyenangkan dan mudah.
									</p>
								</v-col>
								<v-col :cols="$vuetify.display.mobile ? 12 : 6">
									<h3>Cara Perawatan Bouquet Bunga Fresh</h3>
									<h4> Menyimpannya di Dalam Suhu Normal dan Konstan</h4>
									<li class="text-subtitle-2">
										Cara merawat yang pertama adalah menyimpannya dalam suhu normal dan konstan seperti suhu 15-17 derajat Celcius.
									</li>
									<p class="text-subtitle-2" style="color:red;">Buket bunga tidak dianjurkan berada di suhu yang drastis karena bisa membuat buket cepat layu dan mati.</p>
<h4> Ganti Air Bersih Setiap Hari</h4>
<li class="text-subtitle-2"> Buket bunga bisa tahan lama secara maksimal jika airnya diganti setiap hari dengan air segar dan dingin. Gunakan air yang berkualitas. Minimal air tersebut baik untuk diminum manusia. </li>
<h4> Letakkan dalam Ruangan </h4>
<li class="text-subtitle-2"> Pastikan buket bunga tidak terkena sinar matahari secara langsung, jadi kamu bisa meletakkannya di dalam ruangan. </li>
<p class="text-subtitle-2">
Jika ingin bunga mekar lebih cepat, bisa menggunakan air hangat untuk memicu bunga mekar dengan lembut.</p>

								</v-col>
							</v-row>
							<v-divider
									:thickness="2"
									class="border-opacity-25 mt-10"
									color="pink-accent-3"
							></v-divider>
							<h3 class="text-center"> Metode Pembayaran </h3>
							<v-row class="mt-10" v-if="!$vuetify.display.mobile">
								<v-col cols="4"></v-col>
								<v-col>
									<v-img alt="BNI" :src="require(`./assets/BNI.png`)" :height="100"></v-img>
								</v-col>
								<v-col>
									<v-img alt="BCA" :src="require(`./assets/BCA.png`)" :height="100"></v-img>
								</v-col>
								<v-col>
									<v-img alt="OVO" :src="require(`./assets/OVO.png`)" :height="100"></v-img>
								</v-col>
								<v-col cols="4"></v-col>
							</v-row>
							<v-row class="mt-10" v-else>
									<v-img alt="BNI" :src="require(`./assets/BNI.png`)" :height="100"></v-img>
									<v-img alt="BCA" :src="require(`./assets/BCA.png`)" :height="100"></v-img>
									<v-img alt="OVO" :src="require(`./assets/OVO.png`)" :height="100"></v-img>
							</v-row>
            </div>
    </v-main>
  </v-layout>
</template>

<script>
import { useRoute } from 'vue-router'
import ReviewCard from '@/components/ReviewCard.vue'
import FeedCard from '@/components/FeedCard.vue'
import axios from 'axios'
import jsonDataReviews from '@/json/google_reviews_08_2024.json'
import jsonDataFeeds from '@/json/instagram_feed_08_2024.json'
import { useGoTo } from 'vuetify'

export default {
  setup () {
    const goTo = useGoTo()  
    return { goTo }
  },
  components: {
    ReviewCard,
    FeedCard 
  },
  data () {
    return {
     goToOptions: {
        duration: 100,
        easing: 'easeInOutCubic',
        offset: 0
     },
     dataReviews: jsonDataReviews,
     dataFeeds: jsonDataFeeds,
     items: Array.from({ length: 50 }, (_, i) => `This is item ${i + 1}`),
      icons: [
        {icon: 'fa:fa-brands fa-tiktok', url: 'https://www.tiktok.com/@buketgemini'},
        {icon: 'mdi-instagram', url: 'https://instagram.com/buketgemini'}
      ],
      watch: {
        group () {
          this.drawer = false
        },
      },
      drawer: false,
      group: null,
      cart: {
        items: []
      },
      visibleFormSearch: true,
    };
  },
  watch: {
      $route(to, from) {
          if (to.name === 'SignUp') {
            this.visibleFormSearch = false
          } else if (to.name === 'LogIn') {
            this.visibleFormSearch = false
          }
      }
  },
  beforeCreate() {
    this.$store.commit('initializeStore')

    const token = this.$store.state.token

    if (token) {
        axios.defaults.headers.common["Authorization"] = "Token " + token
    } else {
        axios.defaults.headers.common["Authorization"] = ""
    }
  },
  mounted () {
    this.cart = this.$store.state.cart
    document.title = 'Bouquet Gemini'
  },
  computed: {
    cartTotalLength() {
        let totalLength = 0
        for (let i = 0; i < this.cart.items.length; i ++) {
            totalLength += this.cart.items[i].quantity
        }
        return totalLength
    }
  },
  methods: {
    logout () {
        axios.defaults.headers.common["Authorization"] = ""

        localStorage.removeItem('token')
        localStorage.removeItem('username')
        localStorage.removeItem('userid')

        this.$store.commit('removeToken')
        this.$router.push('/')
    }
  }
};
</script>


<style scoped>
.scroll-container {
  white-space: nowrap;
  overflow-x: auto;
  flex-wrap: unset;
}
.scroll-container > .v-col {
  display: inline-block;
  white-space: normal;
}
.scroll-container::-webkit-scrollbar {
  display: none; /* Optional: hide scrollbar for better aesthetics */
}
</style>
