import { createRouter, createWebHistory } from 'vue-router'
import Product from '../views/Product.vue'
import HomeView from '../views/HomeView.vue'
import Category from '../views/Category.vue'
import Search from '../views/Search.vue'
import Cart from '../views/Cart.vue'
import SignUp from '../views/SignUp.vue'
import LogIn from '../views/LogIn.vue'
import MyAccount from '../views/MyAccount.vue'
import CheckOut from '../views/CheckOut.vue'
import About from '@/views/About.vue'
import FAQ from '@/views/FAQ.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQ
  },
  {
    path: '/:category_slug/:product_slug',
    name: 'Product',
    component: Product
  },
  {
    path: '/:category_slug',
    name: 'Category',
    component: Category
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart 
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp 
  },
  {
    path: '/log-in',
    name: 'LogIn',
    component: LogIn
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: MyAccount 
  },
  {
    path: '/checkout',
    name: 'CheckOut',
    component: CheckOut
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiredLogin && !store.state.isAuthenticated)) {
    next({ name: 'LogIn', query: { to: to.path }});
  } else {
    next()
  }
  // to and from are both route objects. must call `next`.
})

export default router
