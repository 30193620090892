<template>
 <swiper
    :slidesPerView="3"
    :centeredSlides="true"
    :spaceBetween="30"
    :lazy="true"
    :modules="modules"
    :autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }"
    :navigation="true"
    :breakpoints="{
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 50,
      },
    }"
  >
    <swiper-slide :key="index" v-for="(feed, index) in feeds" @click.prevent="linkFeed(feed['url'])">
        <v-img :height="550" :width="300" :alt="`${feed['alt']} | ${feed['caption']} | ${feed['hashtags']}`" :lazy-src="feed['displayUrl']" :src="feed['displayUrl']" cover></v-img>
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Navigation } from 'swiper/modules';

import 'swiper/css/navigation';
import 'swiper/css'


export default {
    name: 'FeedCard',
    props: ['feeds'],
    components: {
        Swiper,
        SwiperSlide
    },
    data () {
        return {
          modules: [Autoplay, Navigation],

        }
    },
    methods: {
        linkFeed(url) {
            window.open(url, '_blank')
        }
    },
}
</script>

<style scoped>
.swiper {
  width: 100%;
  height: 550px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 550px;
  object-fit: cover;
}
</style>
