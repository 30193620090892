<template>
	  <v-card v-if="banners.length !== 0">
			<v-carousel
				show-arrows="hover"
				cycle
				hide-delimiter-background
				height="400"
			>
					<v-carousel-item
						v-if="!$vuetify.display.mobile"
						v-for="(banner, i) in banners.banners"
						:src="banner.get_image"
						:key="i"
						cover
					>
					</v-carousel-item>

					<v-carousel-item
						v-if="$vuetify.display.mobile"
						v-for="(banner, i) in banners.banners"
						:src="banner.get_image_mobile"
						:key="i"
						cover
					>
					</v-carousel-item>
		  </v-carousel>
    </v-card>
    <v-container>
      <div v-if="discountProducts.length !== 0" class="discount-products">
        <div class="d-flex justify-center">
          <h3>
            Discount
          </h3>
        </div>
        <v-row class="mt-10">
          <ProductBox :product="product" v-for="product in discountProducts"></ProductBox>
        </v-row>
        <v-divider
            :thickness="2"
            class="border-opacity-15"
            color="pink-accent-3"
        ></v-divider>
      </div>
      <div v-for="sub in subCategoryProducts" v-if="subCategoryProducts.length != 0" class="sub-category-products mt-9">
        <div class="d-flex justify-center">
          <h3>
						{{ sub.name }}
          </h3>
        </div>
        <v-row class="mt-10">
          <ProductBox :product="product" v-for="product in sub.products"></ProductBox>
        </v-row>
      </div>
    </v-container>
</template>

<script>
import axios from 'axios'
import ProductBox from '@/components/ProductBox.vue'

export default {
  name: 'HomeView',
  components: {
    ProductBox
  },
  methods: {
		async getBannerHomepage() {
      this.$store.commit('setIsLoading', true)
      await axios
        .get('/api/v1/sites')
        .then(response => {
          this.banners = response.data[0]
        })
        .catch(error => {
          console.log(error)
        })
      this.$store.commit('setIsLoading', false)
		},
    async getSubCategoryProducts() {
      this.$store.commit('setIsLoading', true)
      await axios
        .get('/api/v1/sub_category')
        .then(response => {
          this.subCategoryProducts= response.data
        })
        .catch(error => {
          console.log(error)
        })
      this.$store.commit('setIsLoading', false)
    },
    async getLatestProducts() {
      this.$store.commit('setIsLoading', true)
      await axios
        .get('/api/v1/latest-products/')
        .then(response => {
          this.latestProducts = response.data
        })
        .catch(error => {
          console.log(error)
        })
      this.$store.commit('setIsLoading', false)
    },
    async getDiscountProducts() {
      this.$store.commit('setIsLoading', true)
      await axios
        .get('/api/v1/discount-products/')
        .then(response => {
          this.discountProducts = response.data
        })
        .catch(error => {
          console.log(error)
        })
      this.$store.commit('setIsLoading', false)
    }
  },
  mounted() {
		this.getBannerHomepage()
    this.getLatestProducts()
    this.getDiscountProducts()
    this.getSubCategoryProducts()
  },
  data () {
    return {
      latestProducts: [],
      discountProducts: [],
      subCategoryProducts: [],
			banners: [],
    }
  }
}
</script>

<style scoped>
  .image {
    margin-top: -1.25rem;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
</style>

<style>
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .9;
    position: absolute;
    width: 100%;
  }
</style>
