<template>
  <v-card
    class="d-flex flex-column py-8 mr-5"
    elevation="10"
    height="auto"
    width="360"
  >
    <div class="d-flex justify-center mt-auto text-h5 ">
     <v-avatar
            size="80"
          >
            <v-img :src="review['Profile image URL']" cover></v-img>
      </v-avatar>
    </div>

    <div class="d-flex align-center flex-column my-auto">
      <div class="text-h2 mt-5">
      </div>

      <v-rating
        :model-value="review.Rating"
        color="yellow-darken-3"
        half-increments
        readonly
      ></v-rating>


        <div class="d-flex justify-center text-wrap text-body-1 mt-5 pa-5">
          " {{ review['Review text'] }} "
        </div>
    </div>
    <v-divider
        :thickness="2"
        class="border-opacity-25"
        color="pink-accent-3"
    ></v-divider>
  </v-card>
</template>

<script>
export default {
    name: 'ReviewCard',
    props: ['review'],
}
</script>

<style scoped>
.rating-values {
   width: 25px;
}
</style>
