<template>
<v-container>
    <h1 class="headline mb-4 text-left">FAQ</h1>
    <v-divider class="mb-8"></v-divider>
    <v-row>
        <v-col cols="5">
            <h2>
                Penggunaan dan Umum
            </h2>
        </v-col>
        <v-col cols="7">
            <v-expansion-panels 
                      v-model="panel"
                        v-for="(item, index) in faqItems" :key="index">
                <v-expansion-panel>
                    <template #title>
                        <h3>
                            {{  item.question }}
                        </h3>
                    </template>
                    <template #text>
                        <p class="text-body-1">
                            {{ item.answer }}
                        </p>
                    </template>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
    </v-row>    
    <v-row>
        <v-col cols="5">
            <h2>
                Bunga dan Cara Merawatnya
            </h2>
        </v-col>
        <v-col cols="7">
            <v-expansion-panels v-for="(item, index) in faqItemsBunga" :key="index">
                <v-expansion-panel>
                    <template #title>
                        <h3>
                            {{  item.question }}
                        </h3>
                    </template>
                    <template #text>
                        <p class="text-body-1">
                            {{ item.answer }}
                        </p>
                    </template>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
    </v-row>    
</v-container>
</template>

<script>
import { useSeoMeta } from '@unhead/vue';
  export default {
    name: 'FAQ',
    mounted() {
        useSeoMeta({
            title: 'FAQ | Bouquet Gemini | Buket Gemini',
            ogUrl: location.host,
            ogType: 'website',
            ogTitle: 'FAQ | Bouquet Gemini | Buket Gemini',
            description: 'FAQ seputar | Bouquet Gemini | Buket Gemini seputar pertanyaan - pertanyaan seperti nomor wa, permintaan khusus, cara memesan, dan kami selalu ada di sekitar kalian buruan beli Toko bunga kami',
            ogDescription: 'FAQ seputar | Bouquet Gemini | Buket Gemini seputar pertanyaan - pertanyaan seperti nomor wa, permintaan khusus, cara memesan, dan kami selalu ada di sekitar kalian buruan beli Toko bunga kami',
        })
    },
    data() {
      return {
        panel: [0, 1],
        faqItems: [
          {
            question: "Bisakah saya meminta pengaturan khusus?",
            answer: "Tentu saja! \n Kami tahu hari-hari istimewa membutuhkan bunga yang istimewa pula. Jangan ragu untuk mengobrol dengan kami melalui Whatsapp untuk mendapatkan karangan bunga yang disesuaikan khusus untuk Anda! Harap dicatat bahwa biaya tambahan mungkin berlaku untuk rangkaian bunga yang dipersonalisasi. :) ",
          },
          {
            question: "Bagaimana cara saya memesan?",
            answer: "Pikirkan tentang seseorang yang spesial bagi Anda. Lalu pilihlah buket bunganya!  Jangan lupa pilih tanggal pengambilan/pengiriman yang Anda inginkan! Isi semua informasi yang diperlukan untuk check out, lalu kami akan menghubungi Anda melalui Whatsapp untuk mengonfirmasi pesanan Anda. :) Anda selalu dapat menyapa melalui Whatsapp untuk membeli karangan bunga. Senang sekali bisa bertemu dengan Anda secara daring! Halo!  "
          },
          {
            question: "Berapa nomor Whatsapp anda?",
            answer: "Whatsapp or Telepon +62813-3170-6861 Tidak sabar untuk berbicara dengan Anda segera! ",
          },
          {
            question: "Apa saja slot untuk pengiriman ?",
            answer: "Koleksi Pribadi: Kami SENANG bertemu orang secara langsung! Datang dan ambil buket bunga cantik di: Jl. Tomang Tinggi Raya No. 17A, Tomang, Grogol – Petamburan Jakarta Barat JAM: Senin - Jumat: 10.00 WIB hingga 19.00 WIB Sabtu: 13.00 WIB hingga 17.00 WIB Minggu: 10.00 WIB hingga 12.00 WIB ",
          },
          {
            question: "Siapa yang melakukan pengiriman?",
            answer: "Kurir pihak ketiga tepercaya Grab, Gojeg, Dan Kurir Pribadi "
          },
        ],
        faqItemsBunga: [
          {
            question: "Darimana bunga Anda berasal?",
            answer: "Kami mencintai #LocalPride, jadi sebagian besar bunga kami ditanam secara lokal dari berbagai dataran tinggi di seluruh negeri (seperti Sukabumi, Bandung, dan bahkan Sumatera). Namun, kami juga menggunakan bunga impor dari Vietnam, Tiongkok, dan bahkan dari Belanda. :) "
          },
          {
            question: "Berapa lama bunganya bisa bertahan?",
            answer: "Bunga segar kami akan bertahan hingga 3-5 hari dalam cuaca Jakarta yang lembap dan hangat. Pastikan untuk menyimpan bunga di ruangan yang berventilasi dan terhindar dari sinar matahari langsung, sehingga Anda dapat terus menikmati keindahannya!  "
          },
          {
            question: "Bagaimana cara merawat bunga?",
            answer: "Bunga dapat dikeluarkan dari bungkusnya setelah 1-2 hari. Potong tangkainya dengan memotong ujungnya secara miring dan masukkan ke dalam vas berisi air segar. Ganti air secara teratur dan pajang bunga di tempat yang sejuk dan terhindar dari sinar matahari langsung. Untuk mencegah kontaminasi, pastikan untuk membuang kelopak, daun, atau tangkai yang layu dari air."
          },
          // Add more FAQ items as needed
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  .headline {
    text-align: center;
  }
  </style>
