<template>
    <tr>
        <td>
            <v-img :alt="item.product.name" :src="item.product.get_image"></v-img>
        </td>
        <td>
            <router-link :to="item.product.get_absolute_url"> {{ item.product.name }}</router-link>
        </td>
        <td>
            {{ formatMoney(item.product.price) }}
        </td>
        <td>
            <v-row align="center" justify="center">
            {{ item.quantity }}
                <v-col cols="auto">
                    <v-btn size="x-small" @click="decrementQuantity(item)">-</v-btn>
                    <v-btn size="x-small" @click="incrementQuantity(item)">+</v-btn>
                </v-col>
            </v-row>
        </td>
        <td>
            Rp. {{ formatMoney(getItemTotal(item)) }}
        </td>
        <td>
            <v-btn size="x-small" icon="mdi-close" color="red" @click="removeFromCart(item)"></v-btn>
        </td>
    </tr>

</template>
<script>
export default {
    name: 'CartItem',
    props: {
        initialItem: Object
    },
    data () {
        return {
            item: this.initialItem
        }
    },
    methods: {
        getItemTotal (item) {
            return item.quantity * item.product.price
        },
        decrementQuantity (item) {
            item.quantity -= 1
            
            if (item.quantity === 0) {
                this.$emit('removeFromCart', item)
            }

            this.updateCart()

        },
        incrementQuantity (item) {
            item.quantity += 1
            this.updateCart()
        },
        updateCart () {
            localStorage.setItem('cart', JSON.stringify(this.$store.state.cart))
        },
        removeFromCart (item) {
            this.$emit('removeFromCart', item)

            this.updateCart()
        }
    }

}
</script>

<script setup>
    function formatMoney(value) {
        return new Intl.NumberFormat('en-GB').format(value).replace(',', '.').replace(',', '.')
    }
</script>
